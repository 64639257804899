// ----------------------------------------------------------------------

const ROOTS = Object.freeze({
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
} as const);

// ----------------------------------------------------------------------

export const paths = Object.freeze({
  // AUTH
  auth: {
    root: `${ROOTS.AUTH}`,
    login: `${ROOTS.AUTH}/login`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    top_driver: `${ROOTS.DASHBOARD}/top-drivers`,
    bad_reviews: `${ROOTS.DASHBOARD}/bad-reviews`,
    join_requests: `${ROOTS.DASHBOARD}/join-request`,
    customers: {
      root: `${ROOTS.DASHBOARD}/customers`,
      cards: `${ROOTS.DASHBOARD}/customers/cards`,
      list: `${ROOTS.DASHBOARD}/customers/list`,
      create: `${ROOTS.DASHBOARD}/customers/create`,
      one: (customerId: string = ':id') => `${ROOTS.DASHBOARD}/customers/show/${customerId}`,
      edit: (customerId: string = ':customerId') =>
        `${ROOTS.DASHBOARD}/customers/edit/${customerId}`,
    },
    drivers: {
      root: `${ROOTS.DASHBOARD}/drivers`,
      cards: `${ROOTS.DASHBOARD}/drivers/cards`,
      list: `${ROOTS.DASHBOARD}/drivers/list`,
      one: (driverId: string = ':id') => `${ROOTS.DASHBOARD}/drivers/show/${driverId}`,
      create: `${ROOTS.DASHBOARD}/drivers/create`,
      edit: (driverId: string = ':driverId') => `${ROOTS.DASHBOARD}/drivers/edit/${driverId}`,
    },
    invoices: `${ROOTS.DASHBOARD}/invoices`,
    orders: {
      root: `${ROOTS.DASHBOARD}/orders`,
      one: (orderId: string = ':id') => `${ROOTS.DASHBOARD}/orders/show/${orderId}`,
      list: `${ROOTS.DASHBOARD}/orders`,
    },
    messages: {
      root: `${ROOTS.DASHBOARD}/messages`,
      create: `${ROOTS.DASHBOARD}/messages/create`,
      list: `${ROOTS.DASHBOARD}/messages/list`,
    },
    admins: {
      root: `${ROOTS.DASHBOARD}/admins`,
      cards: `${ROOTS.DASHBOARD}/admins/cards`,
      list: `${ROOTS.DASHBOARD}/admins/list`,
      one: (adminId: string = ':id') => `${ROOTS.DASHBOARD}/admins/show/${adminId}`,
      create: `${ROOTS.DASHBOARD}/admins/create`,
      edit: (adminId: string = ':adminId') => `${ROOTS.DASHBOARD}/admins/edit/${adminId}`,
    },
    settings: {
      root: `${ROOTS.DASHBOARD}/settings`,
      create_role: `${ROOTS.DASHBOARD}/settings/role/create`,
      update_role: (id: string = ':id') => `${ROOTS.DASHBOARD}/settings/role/update/${id}`,
    },
    coupons: {
      root: `${ROOTS.DASHBOARD}/coupons`,
      list: `${ROOTS.DASHBOARD}/coupons/list`,
      create: `${ROOTS.DASHBOARD}/coupons/create`,
    },
    ads: {
      root: `${ROOTS.DASHBOARD}/ads`,
      list: `${ROOTS.DASHBOARD}/ads/list`,
      create: `${ROOTS.DASHBOARD}/ads/create`,
      update: (id: string = ':id') => `${ROOTS.DASHBOARD}/ads/update/${id}`,
    },
    subscriptions: {
      root: `${ROOTS.DASHBOARD}/subscriptions`,
      list: `${ROOTS.DASHBOARD}/subscriptions/list`,
      view: (id: string = ':id') => `${ROOTS.DASHBOARD}/subscriptions/view/${id}`,
      create: `${ROOTS.DASHBOARD}/subscriptions/create`,
    },
    message_type: {
      root: `${ROOTS.DASHBOARD}/message-type`,
      create: `${ROOTS.DASHBOARD}/message-type/create`,
      update_message_type: (id: string = ':id') => `${ROOTS.DASHBOARD}/message-type/update/${id}`,
    },
  },
  // MAIN
  main: {
    404: '404',
    500: '500',
  },
} as const);
