import { Suspense } from 'react';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import { RouteObject } from 'react-router';
import { paths } from 'src/constants/routes/paths';
import { lazyWithRetries } from 'src/utils/lazy-loading-with-retry';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazyWithRetries(() => import('src/pages/auth/jwt/login.page'));

// ----------------------------------------------------------------------

export const authRoutes: RouteObject[] = [
  {
    path: paths.auth.root,
    children: [
      {
        path: paths.auth.login,
        element: (
          <GuestGuard>
            <Suspense fallback={<SplashScreen />}>
              <AuthClassicLayout>
                <JwtLoginPage />
              </AuthClassicLayout>
            </Suspense>
          </GuestGuard>
        ),
      },
    ],
  },
];
