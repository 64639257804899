import { useMemo } from 'react';
// routes
import { paths } from 'src/constants/routes/paths';
// components
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';
import { DriverAccountStatusType, useNavConfigTotalQueryQuery } from 'src/generated/graphql';
import Label from 'src/components/label/label';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  bad_reviews: icon('ic_bad_reviews'),
  profile: icon('ic_user'),
  invoice: icon('ic_invoice'),
  orders: icon('ic_orders'),
  messages: icon('ic_messages'),
  join_request: icon('ic_join_requests'),
  settings: icon('ic_settings'),
  coupons: icon('ic_coupons'),
  subscriptions: icon('ic_subscriptions'),
  ads: icon('ic_ads'),
};

// ----------------------------------------------------------------------

export function useNavData() {


  const { user } = useAuthContext();

  const permissions = user?.role?.permission;

  const { data: totlaData } = useNavConfigTotalQueryQuery(
    {
      where: {
        accountStatus: { equals: DriverAccountStatusType.Pending },
        car: { id: { not: null } },
      },
    },
    {},
    ['NavConfigTotalQuery']
  );
  const {t} = useLocales();

  const data = useMemo(
    
    () => [
      
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t("overview"),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.app,
            icon: ICONS.dashboard,
            isActive: permissions?.some((e) => ['Home_Show'].includes(e?.name!)),
          },
          {
            title: t("bad_reviews"),
            path: paths.dashboard.bad_reviews,
            icon: ICONS.bad_reviews,
            isActive: permissions?.some((e) => ['BadReviews_Show'].includes(e?.name!)),
          },
          {
            title: t("join_request"),
            path: paths.dashboard.join_requests,
            icon: ICONS.join_request,
            ...(totlaData?.totalJoinRequest &&
              totlaData?.totalJoinRequest !== 0 && {
              info: <Label color="error">+{totlaData?.totalJoinRequest}</Label>,
            }),
            isActive: permissions?.some((e) =>
              ['JoinRequests_Show', 'JoinRequests_Edit'].includes(e?.name!)
            ),
          },
        ],
        isActive: permissions?.some((e) =>
          ['JoinRequests_Show', 'JoinRequests_Edit', 'BadReviews_Show', 'Home_Show'].includes(
            e?.name!
          )
        ),
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t("users"),
        items: [
          {
            title: t("customers"),
            path: paths.dashboard.customers.root,
            icon: ICONS.profile,
            children: [
              {
                title: t("cards"),
                path: paths.dashboard.customers.cards,
                isActive: permissions?.some((e) => ['Customers_Show'].includes(e?.name!)),
              },
              {
                title: t("list"),
                path: paths.dashboard.customers.list,
                isActive: permissions?.some((e) => ['Customers_Show'].includes(e?.name!)),
              },
              {
                title: t("create"),
                path: paths.dashboard.customers.create,
                isActive: permissions?.some((e) => ['Customers_Add'].includes(e?.name!)),
              },
            ],
            isActive: permissions?.some((e) =>
              ['Customers_Show', 'Customers_Add'].includes(e?.name!)
            ),
          },
          {
            title: t("drivers"),
            path: paths.dashboard.drivers.root,
            icon: ICONS.profile,
            children: [
              {
                title: t("cards"),
                path: paths.dashboard.drivers.cards,
                isActive: permissions?.some((e) => ['Drivers_Show'].includes(e?.name!)),
              },
              {
                title: t("list"),
                path: paths.dashboard.drivers.list,
                isActive: permissions?.some((e) => ['Drivers_Show'].includes(e?.name!)),
              },
              {
                title: t("create"),
                path: paths.dashboard.drivers.create,
                isActive: permissions?.some((e) => ['Drivers_Add'].includes(e?.name!)),
              },
            ],
            isActive: permissions?.some((e) => ['Drivers_Show', 'Drivers_Add'].includes(e?.name!)),
          },
          {
            title: t("admins"),
            path: paths.dashboard.admins.root,
            icon: ICONS.profile,
            children: [
              {
                title: t('cards'),
                path: paths.dashboard.admins.cards,
                isActive: permissions?.some((e) => ['Admins_Show'].includes(e?.name!)),
              },
              {
                title:  t("list"),
                path: paths.dashboard.admins.list,
                isActive: permissions?.some((e) => ['Admins_Show'].includes(e?.name!)),
              },
              {
                title: t("create"),
                path: paths.dashboard.admins.create,
                isActive: permissions?.some((e) => ['Admins_Add'].includes(e?.name!)),
              },
            ],
            isActive: permissions?.some((e) => ['Admins_Show', 'Admins_Add'].includes(e?.name!)),
          },
        ],
        isActive: permissions?.some((e) =>
          [
            'Customers_Show',
            'Customers_Add',
            'Customers_Edit',
            'Customers_Delete',
            'Drivers_Show',
            'Drivers_Add',
            'Drivers_Edit',
            'Drivers_Delete',
            'Admins_Show',
            'Admins_Add',
            'Admins_Edit',
            'Admins_Delete',
          ].includes(e?.name!)
        ),
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('invoices'),
            path: paths.dashboard.invoices,
            icon: ICONS.invoice,
            isActive: permissions?.some((e) => ['Invoices_Show'].includes(e?.name!)),
          },
          {
            title: t('orders'),
            path: paths.dashboard.orders.root,
            icon: ICONS.orders,
            isActive: permissions?.some((e) => ['Orders_Show'].includes(e?.name!)),
          },
          {
            title: t("messages"),
            path: paths.dashboard.messages.root,
            icon: ICONS.messages,
            children: [
              {
                title: t('create'),
                path: paths.dashboard.messages.create,
                isActive: permissions?.some((e) => ['Messages_Add'].includes(e?.name!)),
              },
              {
                title: t('list'),
                path: paths.dashboard.messages.list,
                isActive: permissions?.some((e) => ['Messages_Show'].includes(e?.name!)),
              },
            ],
            isActive: permissions?.some((e) =>
              ['Messages_Show', 'Messages_Add'].includes(e?.name!)
            ),
          },
          {
            title: t("ads"),
            path: paths.dashboard.ads.root,
            icon: ICONS.ads,
            children: [
              {
                title: t('create'),
                path: paths.dashboard.ads.create,
                isActive: permissions?.some((e) => ['ADS_Add'].includes(e?.name!)),
              },
              {
                title: t('list'),
                path: paths.dashboard.ads.list,
                isActive: permissions?.some((e) => ['ADS_Show'].includes(e?.name!)),
              },
            ],
            isActive: permissions?.some((e) => ['ADS_Show', 'ADS_Add'].includes(e?.name!)),
          },
          {
            title: t('coupons'),
            path: paths.dashboard.coupons.root,
            icon: ICONS.coupons,
            isActive: permissions?.some((e) => ['Coupons_Show', 'Coupons_Add'].includes(e?.name!)),
            children: [
              {
                title: t('create'),
                path: paths.dashboard.coupons.create,
                isActive: permissions?.some((e) => ['Coupons_Add'].includes(e?.name!)),
              },
              {
                title: t('list'),
                path: paths.dashboard.coupons.list,
                isActive: permissions?.some((e) => ['Coupons_Show'].includes(e?.name!)),
              },
            ],
          },
          {
            title: t("subscriptions"),
            path: paths.dashboard.subscriptions.root,
            icon: ICONS.subscriptions,
            children: [
              {
                title: t('create'),
                path: paths.dashboard.subscriptions.create,
                isActive: permissions?.some((e) =>
                  ['Drivers_Subscriptions_Add'].includes(e?.name!)
                ),
              },
              {
                title: t('list'),
                path: paths.dashboard.subscriptions.list,
                isActive: permissions?.some((e) =>
                  ['Drivers_Subscriptions_Show'].includes(e?.name!)
                ),
              },
            ],
            isActive: permissions?.some((e) =>
              ['Drivers_Subscriptions_Show', 'Drivers_Subscriptions_Add'].includes(e?.name!)
            ),
          },
          {
            title: t("settings"),
            path: paths.dashboard.settings.root,
            icon: ICONS.settings,
            isActive: permissions?.some((e) => ['Settings_Show'].includes(e?.name!)),
          },
        ],
        isActive: permissions?.some((e) =>
          [
            'Invoices_Show',
            'Orders_Show',
            'Messages_Add',
            'Messages_Show',
            'ADS_Show',
            'ADS_Add',
            'Coupons_Show',
            'Coupons_Add',
            'Drivers_Subscriptions_Show',
            'Drivers_Subscriptions_Add',
            'Settings_Show',
          ].includes(e?.name!)
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions, totlaData?.totalJoinRequest]
  );

  return data;
}
