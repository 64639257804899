import { Outlet, RouteObject } from 'react-router-dom';
import { paths } from 'src/constants/routes/paths';
// layouts
import CompactLayout from 'src/layouts/compact';
import { lazyWithRetries } from 'src/utils/lazy-loading-with-retry';

// ----------------------------------------------------------------------

const Page404 = lazyWithRetries(() => import('src/pages/404.page'));

const Page500 = lazyWithRetries(() => import('src/pages/500.page'));

// ----------------------------------------------------------------------

export const mainRoutes: RouteObject[] = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: paths.main[404], element: <Page404 /> },
      { path: paths.main[500], element: <Page500 /> },
    ],
  },
];
