import { QueryClient } from '@tanstack/react-query';

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1 * 60 * 60 * 1000,
      retry: true,
      cacheTime: 0,
    },
  },
});
