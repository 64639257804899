// routes
import { paths } from 'src/constants/routes/paths';

// API
// ----------------------------------------------------------------------

export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const KEYSTONE_GRAPHQL_URL = process.env.REACT_APP_KEYSTONE_GRAPHQL_URL!;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.app; // as '/dashboard'
