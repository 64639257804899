import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { useLocales } from 'src/locales';
import { MotionContainer, varBounce } from 'src/components/animate';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  acceptedPermissions?: string[];
  children: React.ReactNode;
};

export default function PermissionBasedGuard({

  acceptedPermissions,
  children,
}: RoleBasedGuardProp) {
  const { user } = useAuthContext();
  const {t} = useLocales();


  const permissions = user?.role?.permission;

  if (!permissions?.some((e) => acceptedPermissions?.includes(e.name!))) {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {t("you_dont_have_permission")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>{t("forbidden")}</m.div>
      </Container>
    );
  }

  return <> {children} </>;
}
