import React from 'react';

export const lazyWithRetries: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      window.location.reload();
      throw error;
    }
  };
  return React.lazy(retryImport);
};

// source of the problem https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
