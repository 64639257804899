import { CheckboxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// ----------------------------------------------------------------------

const Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17.9 2.318A5 5 0 0 1 22.895 7.1l.005.217v10a5 5 0 0 1-4.783 4.995l-.217.005h-10a5 5 0 0 1-4.995-4.783l-.005-.217v-10a5 5 0 0 1 4.783-4.996l.217-.004h10Zm-.5 1.5h-9a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4Z" />
  </SvgIcon>
);

const CheckedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17 2a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5Zm-1.625 7.255-4.13 4.13-1.75-1.75a.881.881 0 0 0-1.24 0c-.34.34-.34.89 0 1.24l2.38 2.37c.17.17.39.25.61.25.23 0 .45-.08.62-.25l4.75-4.75c.34-.34.34-.89 0-1.24a.881.881 0 0 0-1.24 0Z" />
  </SvgIcon>
);

export const GradientCheckedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM16.615 10.495L11.865 15.245C11.695 15.415 11.475 15.495 11.245 15.495C11.025 15.495 10.805 15.415 10.635 15.245L8.255 12.875C7.915 12.525 7.915 11.975 8.255 11.635C8.595 11.295 9.155 11.295 9.495 11.635L11.245 13.385L15.375 9.255C15.715 8.915 16.275 8.915 16.615 9.255C16.955 9.605 16.955 10.155 16.615 10.495Z"
        fill="url(#paint0_linear_1254_2618)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1254_2618"
          x1="12"
          y1="2"
          x2="12"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B6A3" />
          <stop offset="1" stopColor="#56CAE4" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

const IndeterminateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17,2 C19.7614,2 22,4.23858 22,7 L22,7 L22,17 C22,19.7614 19.7614,22 17,22 L17,22 L7,22 C4.23858,22 2,19.7614 2,17 L2,17 L2,7 C2,4.23858 4.23858,2 7,2 L7,2 Z M15,11 L9,11 C8.44772,11 8,11.4477 8,12 C8,12.5523 8.44772,13 9,13 L15,13 C15.5523,13 16,12.5523 16,12 C16,11.4477 15.5523,11 15,11 Z" />
  </SvgIcon>
);

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    gradient: true;
  }
}

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        icon: <Icon />,
        checkedIcon: <CheckedIcon />,
        indeterminateIcon: <IndeterminateIcon />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => ({
          padding: theme.spacing(1),
          ...(ownerState.color === 'error' && {
            checkedIcon: <GradientCheckedIcon />,
          }),
        }),
      },
    },
  };
}
