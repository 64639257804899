import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from 'src/locales';
import { JwtLoginView } from 'src/features/auth';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('login_header')} </title>
      </Helmet>

      <JwtLoginView />
    </>
  );
}
