import { Suspense } from 'react';
import { Outlet, type RouteObject } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from 'src/constants/routes/paths';
import { lazyWithRetries } from 'src/utils/lazy-loading-with-retry';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';

// ----------------------------------------------------------------------

const HomePage = lazyWithRetries(() => import('src/pages/dashboard/home-page.page'));
const BadReviews = lazyWithRetries(() => import('src/pages/dashboard/bad-reviews.page'));
// Customers
const CustomersCards = lazyWithRetries(
  () => import('src/pages/dashboard/customers/customers-list-card.page')
);
const CustomersCreate = lazyWithRetries(
  () => import('src/pages/dashboard/customers/create-customer.page')
);
const CustomersList = lazyWithRetries(
  () => import('src/pages/dashboard/customers/customer-list.page')
);
const ShowCustomerPage = lazyWithRetries(
  () => import('src/pages/dashboard/customers/show-customer.page')
);
// Drivers
const DriversCards = lazyWithRetries(
  () => import('src/pages/dashboard/drivers/drivers-list-card.page')
);
const ShowDriverPage = lazyWithRetries(
  () => import('src/pages/dashboard/drivers/show-driver.page')
);
const DriversCreate = lazyWithRetries(
  () => import('src/pages/dashboard/drivers/create-driver.page')
);
const DriversList = lazyWithRetries(() => import('src/pages/dashboard/drivers/drivers-list.page'));
const TopDrivers = lazyWithRetries(() => import('src/pages/dashboard/top-drivers.page'));
// Admins
const AdminsCards = lazyWithRetries(
  () => import('src/pages/dashboard/admins/admin-list-card.page')
);
const AdminsCreate = lazyWithRetries(() => import('src/pages/dashboard/admins/admin-create.page'));
const AdminsList = lazyWithRetries(() => import('src/pages/dashboard/admins/admin-list.page'));
const AdminShowOne = lazyWithRetries(() => import('src/pages/dashboard/admins/show-admin.page'));
// Invoices
const Invoices = lazyWithRetries(() => import('src/pages/dashboard/invoices.page'));
// Order
const OrdersPage = lazyWithRetries(() => import('src/pages/dashboard/orders/orders-list.page'));
const ShowOrderPage = lazyWithRetries(() => import('src/pages/dashboard/orders/show-order.page'));
// Messages
const CreateMessagePage = lazyWithRetries(
  () => import('src/pages/dashboard/messages/craete-message.page')
);
const MessagesListPage = lazyWithRetries(
  () => import('src/pages/dashboard/messages/messages-list.page')
);
// join requests
const JoinRequests = lazyWithRetries(() => import('src/pages/dashboard/join-request.page'));
// Settings
const SettingsPage = lazyWithRetries(() => import('src/pages/dashboard/settings/settings.page'));
const CreateRolePage = lazyWithRetries(
  () => import('src/pages/dashboard/settings/create-role.page')
);
const UpdateRolePage = lazyWithRetries(
  () => import('src/pages/dashboard/settings/update-role.page')
);
// Coupons
const CouponsListPage = lazyWithRetries(
  () => import('src/pages/dashboard/coupons/coupons-list.page')
);
const NewCouponPage = lazyWithRetries(
  () => import('src/pages/dashboard/coupons/create-coupon.page')
);
// Coupons
const NewAdPage = lazyWithRetries(() => import('src/pages/dashboard/ads/create-ad.page'));
const AdsListPage = lazyWithRetries(() => import('src/pages/dashboard/ads/ads-list.page'));
// Packages
const PackagesListPage = lazyWithRetries(
  () => import('src/pages/dashboard/packages/packages-list.page')
);
const NewPackagePage = lazyWithRetries(
  () => import('src/pages/dashboard/packages/craete-package.page')
);
const PackagePage = lazyWithRetries(() => import('src/pages/dashboard/packages/package-page'));
// Messages Type
const CreateUpdateMessageTypePage = lazyWithRetries(
  () => import('src/pages/dashboard/message-type/craete-message-type.page')
);
// ----------------------------------------------------------------------

export const dashboardRoutes: RouteObject[] = [
  {
    path: paths.dashboard.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <PermissionBasedGuard acceptedPermissions={['Home_Show']}>
            <HomePage />
          </PermissionBasedGuard>
        ),
        index: true,
      },
      {
        path: paths.dashboard.app,
        element: (
          <PermissionBasedGuard acceptedPermissions={['Home_Show']}>
            <HomePage />
          </PermissionBasedGuard>
        ),
      },
      {
        path: paths.dashboard.bad_reviews,
        element: (
          <PermissionBasedGuard acceptedPermissions={['BadReviews_Show']}>
            <BadReviews />
          </PermissionBasedGuard>
        ),
      },
      {
        path: paths.dashboard.top_driver,
        element: (
          <PermissionBasedGuard acceptedPermissions={['Drivers_Show']}>
            <TopDrivers />
          </PermissionBasedGuard>
        ),
      },
      {
        path: paths.dashboard.join_requests,
        element: (
          <PermissionBasedGuard acceptedPermissions={['JoinRequests_Show']}>
            <JoinRequests />
          </PermissionBasedGuard>
        ),
      },
      {
        path: paths.dashboard.customers.root,
        children: [
          {
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Show']}>
                <CustomersCards />
              </PermissionBasedGuard>
            ),
            index: true,
          },
          {
            path: paths.dashboard.customers.cards,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Show']}>
                <CustomersCards />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.customers.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Show']}>
                <CustomersList />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.customers.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Add']}>
                <CustomersCreate />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.customers.edit(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Edit']}>
                <CustomersCreate />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.customers.one(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Customers_Show']}>
                <ShowCustomerPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.drivers.root,
        children: [
          {
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Show']}>
                <DriversCards />
              </PermissionBasedGuard>
            ),
            index: true,
          },
          {
            path: paths.dashboard.drivers.cards,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Show']}>
                <DriversCards />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.drivers.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Show']}>
                <DriversList />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.drivers.one(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Show']}>
                <ShowDriverPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.drivers.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Add']}>
                <DriversCreate />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.drivers.edit(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Edit']}>
                <DriversCreate />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.admins.root,
        children: [
          {
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Show']}>
                <AdminsCards />
              </PermissionBasedGuard>
            ),
            index: true,
          },
          {
            path: paths.dashboard.admins.cards,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Show']}>
                <AdminsCards />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.admins.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Show']}>
                <AdminsList />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.admins.one(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Show']}>
                <AdminShowOne />
              </PermissionBasedGuard>
            ),
          },

          {
            path: paths.dashboard.admins.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Add']}>
                <AdminsCreate />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.admins.edit(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Admins_Edit']}>
                <AdminsCreate />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.invoices,
        element: (
          <PermissionBasedGuard acceptedPermissions={['Invoices_Show']}>
            <Invoices />
          </PermissionBasedGuard>
        ),
      },
      {
        path: paths.dashboard.orders.root,
        children: [
          {
            path: paths.dashboard.orders.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Orders_Show']}>
                <OrdersPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.orders.one(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Orders_Show']}>
                <ShowOrderPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.messages.root,
        children: [
          {
            path: paths.dashboard.messages.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Messages_Add']}>
                <CreateMessagePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.messages.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Messages_Show']}>
                <MessagesListPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.settings.root,
        children: [
          {
            index: true,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Settings_Show']}>
                <SettingsPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.settings.create_role,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Settings_Edit']}>
                <CreateRolePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.settings.update_role(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Settings_Edit']}>
                <UpdateRolePage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.coupons.root,
        children: [
          {
            index: true,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Coupons_Show']}>
                <CouponsListPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.coupons.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Coupons_Add']}>
                <NewCouponPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.coupons.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Coupons_Show']}>
                <CouponsListPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.ads.root,
        children: [
          {
            index: true,
            element: (
              <PermissionBasedGuard acceptedPermissions={['ADS_Show']}>
                <AdsListPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.ads.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['ADS_Show']}>
                <AdsListPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.ads.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['ADS_Add']}>
                <NewAdPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.ads.update(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['ADS_Edit']}>
                <NewAdPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.subscriptions.root,
        children: [
          {
            index: true,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Subscriptions_Show']}>
                <PackagesListPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.subscriptions.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Subscriptions_Add']}>
                <NewPackagePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.subscriptions.list,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Subscriptions_Show']}>
                <PackagesListPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.subscriptions.view(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Drivers_Subscriptions_Show']}>
                <PackagePage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: paths.dashboard.message_type.root,
        children: [
          {
            path: paths.dashboard.message_type.create,
            element: (
              <PermissionBasedGuard acceptedPermissions={['Settings_Edit']}>
                <CreateUpdateMessageTypePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: paths.dashboard.message_type.update_message_type(),
            element: (
              <PermissionBasedGuard acceptedPermissions={['Settings_Edit']}>
                <CreateUpdateMessageTypePage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
    ],
  },
];
