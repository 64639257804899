import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils

import { GetAdminByIdDocument, useAdminLoginMutation } from 'src/generated/graphql';
import axiosInstance from 'src/utils/axios';
import { reactQueryClient } from 'src/utils/react-query-client';
import { LocalStorageKeys } from 'src/constants/local-storage-keys';
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { AuthContext } from './auth-context';
import { setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { mutate: _login, isLoading: loginLoading } = useAdminLoginMutation({
    onSuccess: (res) => {
      if (
        res.authenticateAdminWithPassword?.__typename === 'AdminAuthenticationWithPasswordSuccess'
      ) {
        const accessToken = res.authenticateAdminWithPassword.sessionToken;

        setSession(accessToken);

        localStorage.setItem(
          LocalStorageKeys.USER_ID_KEY,
          res.authenticateAdminWithPassword.item.id
        );

        const GetMeQuery = {
          queryKey: ['GetMe'],
          queryFn: async () =>
            axiosInstance.post('', {
              query: GetAdminByIdDocument,
              // @ts-ignore
              variables: { where: { id: res?.authenticateAdminWithPassword?.item?.id! } },
            }),
        };

        reactQueryClient
          .fetchQuery(GetMeQuery)
          .then((res1) => {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: res1.data.data.admin,
                isAuthenticated: true,
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (
        res.authenticateAdminWithPassword?.__typename === 'AdminAuthenticationWithPasswordFailure'
      ) {
        console.error(res.authenticateAdminWithPassword.message);
      }
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorageGetItem(LocalStorageKeys.ACCESS_TOKEN);
      const userId = localStorageGetItem(LocalStorageKeys.USER_ID_KEY);

      if (accessToken) {
        const GetMeQuery = {
          queryKey: ['GetMe'],
          queryFn: async () =>
            axiosInstance.post('', {
              query: GetAdminByIdDocument,
              variables: { where: { id: userId } },
            }),
        };

        reactQueryClient
          .fetchQuery(GetMeQuery)
          .then((res) => {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: res.data.data.admin,
                isAuthenticated: true,
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          isAuthenticated: false,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    _login({ password, phone: email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loginLoading,
      isAuthenticated: state.isAuthenticated,
      isInitialized: state.isInitialized,
      method: 'jwt',
      //
      login,
      logout,
    }),
    [state.user, state.isAuthenticated, state.isInitialized, loginLoading, login, logout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
