// @mui
import { enUS, arSA } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Arabic',
    value: 'ar',
    systemValue: arSA,
    icon: 'flagpack:sa',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0]; // Arabic
