import { KEYSTONE_GRAPHQL_URL } from '../config-global';
import axiosInstance from './axios';

export const useAxios =
  <TData, TVariables>(query: string): ((variables?: TVariables) => Promise<TData>) =>
  async (variables?: TVariables) =>
    axiosInstance
      .post<{ data: TData; errors: unknown }>(KEYSTONE_GRAPHQL_URL, {
        query,
        variables,
      })
      .then((res) => {
        if (res.data.errors) throw new Error(JSON.stringify(res.data.errors));
        return res.data.data;
      });
