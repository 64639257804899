// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const upMd = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        py: { xs: 10 },
        ...(upMd && { width: '35%' }),
      }}
      alignItems="center"
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Box
      sx={{ width: '65%' }}
      py={2}
      component="img"
      alt="auth"
      src="/assets/illustrations/login.png"
    />
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        height: '100%',
        px: 2,
        ...(!upMd && { alignItems: 'center' }),
      }}
      spacing={5}
    >
      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}
